<template>
  <div>
    <form>
      <v-card class="mx-auto" max-width="500">
        <v-img src="@/assets/logImage.jpg" aspect-ratio="1"></v-img>
        <!-- <v-card-title class="accent white--text darken-2"></v-card-title> -->
        <v-container>
          <v-row justify="center">
            <div>
              <v-card flat width="500px" class="mx-auto">
                <v-card-text>
                  <v-form ref="form" v-model="valid">
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-text-field placeholder="اسم المستخدم" v-model="userName" required />
                      </v-col>
                    </v-row>
                    <v-row class="mb-2">
                      <v-col>
                        <v-text-field
                          placeholder="كلمة المرور"
                          v-model="password"
                          type="password"
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />

          <v-btn
            :disabled="!valid"
            color="primary"
            class="mr-4"
            @click="logIn"
            :loading="isClick"
          >تسجيل الدخول</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: "",
      password: "",
      valid: false,
      isClick: false
    };
  },
  methods: {
    logIn() {
      this.$store
        .dispatch("retrieveToken", {
          userName: this.userName,
          password: this.password
        })
        .then(() => {
          this.$router.push("/");
     
        });
    }
  }
};
</script>
